const scrlBtnActive = (() => {

  const scrlBtnF = document.querySelector('.ftr-scrl-btn');
  const scrlBtnT = document.querySelector('.top-scrl-btn');

  window.addEventListener('scroll',function(){
    var sH = window.scrollY;
    if(sH >= 300){
      scrlBtnF.classList.remove('active')
      scrlBtnT.classList.add('active')
    }else{
      scrlBtnF.classList.add('active')
      scrlBtnT.classList.remove('active')
    }
  });
})();
export default scrlBtnActive;