const faq = (() => {
  const faqList = document.getElementsByClassName("js-faq");
  console.log(faqList);

  for (let i = 0; i < faqList.length; i++) {
    faqList[i].addEventListener("click", function() {
      this.classList.toggle('active');
    });
  }
})();
export default faq;