const navActive = (() => {

  const navH = document.querySelectorAll('.nav-h li');
  console.log(navH);
  if (location.href.match('/service/')) {
    navH[0].classList.add('active');
  }else if (location.href.match('/products/')) {
    navH[1].classList.add('active');
  }else if (location.href.match('/about/')) {
    navH[2].classList.add('active');
  }else if (location.href.match('/contact/') || location.href.match('/estimate/') || location.href.match('/sample/')) {
    navH[3].classList.add('active');
  }else if (location.href.match('/news/')) {
    navH[4].classList.add('active');
  }else if (location.href.match('/faq/')) {
    navH[5].classList.add('active');
  }else if (location.href.match('/works/')) {
    navH[6].classList.add('active');
  }
})();
export default navActive;