export { default as funcUserAgent } from './funcUserAgent';
export { default as funcWindowResize } from './funcWindowResize';
export { default as funcScrollControl } from './funcScrollControl';
export { default as funcScrollAnchor } from './funcScrollAnchor';
export { default as funcNav } from './funcNav';
export { default as funcLoadImg } from './funcLoadImg';
export { default as classDistortion } from './classDistortion';
export { default as funcLoad } from './funcLoad';


export { default as comFade } from './comFade';
export { default as comImgFade } from './comImgFade';


export { default as topMainSlide } from './topMainSlide';


export { default as funcEntry } from './funcEntry';

export { default as scrlBtnActive } from './scrlBtnActive';
export { default as navActive } from './navActive';
export { default as faq } from './faq';
