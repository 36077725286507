const topMainSlide = (() => {

  const pageTop = document.getElementById('pageTop');
  if (pageTop) {

    let sW = window.innerWidth;
    let sH = window.innerHeight;

    const jsSecMain = document.getElementById('jsSecMain');
    const jsMainWrap = jsSecMain.querySelector('.js-main-wrap');
    const jsSwiper = jsSecMain.querySelector('.js-swiper');

    // jsMainWrap.style.height = `${sH}px`

    const swiper = new Swiper(jsSwiper, {
      slidesPerView: 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      effect: 'fade',
      speed: 1000,
    });

  }


})();
export default topMainSlide;